import {
    Divider,
    HStack,
    IconButton,
    Kbd,
    Text,
    Tooltip,
    useColorMode,
    VStack,
} from '@chakra-ui/react';
import { Group } from '@visx/group';
import type { ProvidedZoom } from '@visx/zoom/lib/types';
import * as React from 'react';
import { BsChevronBarDown as IconCollapse } from 'react-icons/bs';
import {
    VscRefresh as IconReset,
    VscAdd as IconZoomIn,
    VscRemove as IconZoomOut,
} from 'react-icons/vsc';
import { useSetRecoilState } from 'recoil';
import { isVisualTreeCollapsed } from '../../../state/ui/resizable-boards';

/**
 * These are missing export interface from @visx/Zoom
 */
interface TransformMatrix {
    translateX: number;
    translateY: number;
    scaleX: number;
    scaleY: number;
    skewX: number;
    skewY: number;
}

interface ZoomState {
    initialTransformMatrix: TransformMatrix;
    transformMatrix: TransformMatrix;
    isDragging: boolean;
}

interface TreeZoomWrapperProps {
    children: React.ReactNode;
    width: number;
    height: number;
    zoom: ProvidedZoom<Element> & ZoomState;
}

export const TreeZoomWrapper: React.FC<TreeZoomWrapperProps> = ({
    zoom,
    width,
    height,
    children,
}) => {
    const { colorMode } = useColorMode();

    const setVisualTreeCollapsed = useSetRecoilState(isVisualTreeCollapsed);

    const [spacePressed, setSpacePressed] = React.useState<boolean>(false);

    const initialOffsets = React.useMemo(
        () => ({ x: 200, y: height / 2 }),
        [height]
    );

    const setTranslate = zoom.setTranslate;

    const hasMountedRef = React.useRef(false);

    React.useEffect(() => {
        if (hasMountedRef.current === true) {
            return;
        }

        hasMountedRef.current = true;

        // position the tree
        setTranslate({
            translateX: initialOffsets.x,
            translateY: initialOffsets.y,
        });
    }, [initialOffsets, setTranslate]);

    // Position tree and scale to 1
    const reset = () => {
        zoom.setTransformMatrix({
            scaleX: 1,
            scaleY: 1,
            translateX: initialOffsets.x,
            translateY: initialOffsets.y,
            skewX: 0,
            skewY: 0,
        });
    };

    const collapse = () => setVisualTreeCollapsed(true);

    const svgRef = React.useRef<SVGSVGElement>(null);

    // Listen to space press
    React.useEffect(() => {
        const listenSpaceBar = (evt: globalThis.KeyboardEvent) => {
            if (evt.code === 'Space') {
                setSpacePressed(true);
            }
        };

        const muteSpacePress = () => {
            setSpacePressed(false);
        };

        document.addEventListener('keydown', listenSpaceBar, false);
        document.addEventListener('keyup', muteSpacePress, false);

        return () => {
            document.removeEventListener('keydown', listenSpaceBar, false);
            document.removeEventListener('keyup', muteSpacePress, false);
        };
    }, []);

    return (
        <>
            <svg
                width={width}
                height={height}
                ref={svgRef}
                onWheel={zoom.handleWheel}
            >
                <Group top={0} left={0} transform={zoom.toString()}>
                    {children}
                </Group>

                <rect
                    width={width}
                    height={height}
                    rx={14}
                    fill="transparent"
                    style={{
                        cursor: zoom.isDragging ? 'grabbing' : 'grab',
                        pointerEvents: spacePressed ? 'auto' : 'none',
                    }}
                    onTouchStart={zoom.dragStart}
                    onTouchMove={zoom.dragMove}
                    onTouchEnd={zoom.dragEnd}
                    onMouseDown={zoom.dragStart}
                    onMouseMove={zoom.dragMove}
                    onMouseUp={zoom.dragEnd}
                    onMouseLeave={() => {
                        if (zoom.isDragging) zoom.dragEnd();
                    }}
                />
            </svg>

            <VStack
                position="absolute"
                top="0"
                left="0"
                h="100%"
                width="9"
                py="2"
                spacing="3"
                bg={colorMode === 'dark' ? 'gray.800' : 'white'}
            >
                <VStack spacing="3">
                    <Tooltip label="zoom in" placement="right">
                        <IconButton
                            aria-label="zoom-in"
                            size="xs"
                            variant="ghost"
                            icon={<IconZoomIn />}
                            onClick={() =>
                                zoom.scale({ scaleX: 1.2, scaleY: 1.2 })
                            }
                        />
                    </Tooltip>

                    <Tooltip label="zoom out" placement="right">
                        <IconButton
                            aria-label="zoom-out"
                            size="xs"
                            variant="ghost"
                            icon={<IconZoomOut />}
                            onClick={() =>
                                zoom.scale({ scaleX: 0.8, scaleY: 0.8 })
                            }
                        />
                    </Tooltip>
                    <Divider orientation="horizontal" w="100%" h="1" />
                    <Tooltip label="reset view" placement="right">
                        <IconButton
                            aria-label="reset"
                            size="xs"
                            variant="ghost"
                            icon={<IconReset />}
                            onClick={reset}
                        />
                    </Tooltip>
                </VStack>

                <VStack flex="1" justifyContent="flex-end">
                    <Tooltip label="hide board" placement="right">
                        <IconButton
                            aria-label="hide"
                            size="xs"
                            variant="ghost"
                            icon={<IconCollapse />}
                            onClick={collapse}
                        />
                    </Tooltip>
                </VStack>
            </VStack>

            <HStack
                position="absolute"
                left="40px"
                bottom="3px"
                spacing="5"
                bg="whiteAlpha.100"
                px="2"
                rounded="md"
                pointerEvents="none"
            >
                <Text fontSize="xs">
                    <Kbd>SpaceBar</Kbd> + Drag to pan,
                </Text>
                <Text fontSize="xs">Mouse scroll to zoom</Text>
            </HStack>
        </>
    );
};
