import type { ColorMode } from '@chakra-ui/react';

export const trackingFieldStyles = (colorMode: ColorMode) => {
    const isDark = colorMode === 'dark';

    return {
        label: {
            fontSize: 'xs',
            opacity: isDark ? '0.6' : '0.7',
            mb: '1',
        },
        input: {
            fontSize: 'sm',
            fontWeight: 'bold',
            h: '9',
            borderColor: isDark ? 'gray.600' : 'gray.200',
            bg: isDark ? 'rgba(0,0,0,0.05)' : 'gray.50',
            _hover: {
                bg: isDark ? 'rgba(0,0,0,0.3)' : 'inherit',
            },
        },
        upload: {
            fontSize: 'sm',
            fontWeight: 'bold',
            overflow: 'hidden',
            h: '36',
            borderRadius: 'lg',
            border: '1px solid',
            borderColor: isDark ? 'gray.600' : 'gray.200',
            bg: isDark ? 'rgba(0,0,0,0.05)' : 'gray.50',
            _hover: {
                bg: isDark ? 'rgba(0,0,0,0.3)' : 'inherit',
            },
        },
    };
};
