import type { InputProps } from '@chakra-ui/react';
import { FormControl, FormLabel, useColorMode } from '@chakra-ui/react';
import { TrimmedInput } from '@dmp/shared/components';
import { memo } from 'react';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';

interface SurveySponsoredCopyProps {
    value: string;
    onChange: (logo: string) => void;
    placeholder?: InputProps['placeholder'];
    isDisabled?: boolean;
}

export const SurveySponsoredCopy = memo(
    ({
        value,
        onChange,
        placeholder,
        isDisabled,
    }: SurveySponsoredCopyProps) => {
        const { colorMode } = useColorMode();
        const { label, input } = trackingFieldStyles(colorMode);

        return (
            <FormControl id="survey-sponsored-copy" isDisabled={isDisabled}>
                <FormLabel {...label}>Sponsored Copy</FormLabel>
                <TrimmedInput
                    {...input}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                />
            </FormControl>
        );
    }
);
