import { useToast } from '@chakra-ui/react';
import { publishSurveyProof } from '@dmp/qqms/data-access';
import { useErrorToaster } from '@dmp/shared/components';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';

export function useLiveProof(surveyId: string) {
    const toast = useToast();
    const toastRef = useRef<string | number | undefined>();
    const { handleError } = useErrorToaster();

    const closeToast = useCallback(() => {
        if (toastRef.current) {
            toast.close(toastRef.current);
        }

        toastRef.current = undefined;
    }, [toast]);

    const query = useQuery(
        ['live-proof', surveyId],
        () => publishSurveyProof(surveyId),
        {
            enabled: false,
            onSuccess: ({ url }) => {
                window.open(url);
                closeToast();
            },
            onError: (e) => {
                console.error(e);
                closeToast();
                handleError('Error generating live proof');
            },
        }
    );

    const initiate = useCallback(() => {
        toastRef.current = toast({
            title: 'Proofing',
            status: 'info',
            description: 'Only saved settings will be proofed',
            isClosable: true,
            duration: 10000,
        });

        query.refetch();
    }, [query, toast]);

    return {
        isProofing: query.isFetching,
        initiate,
    };
}
