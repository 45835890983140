import type { ColorMode } from '@chakra-ui/react';

import { usaTodayBlue } from '../foundations/colors';

export const questionFieldStyles = (colorMode: ColorMode) => {
    const isDark = colorMode === 'dark';

    return {
        input: {
            className: 'scrollbar',
            color: usaTodayBlue(colorMode),
            fontWeight: '900',
            bg: isDark ? 'rgba(0,0,0,0.05)' : 'gray.50',
            borderColor: isDark ? 'gray.600' : 'gray.200',

            _hover: {
                bg: isDark ? 'rgba(0,0,0,0.3)' : 'inherit',
            },
            _focus: {
                background: isDark ? 'gray.800' : 'gray.50',
            },
            _placeholder: {
                fontWeight: '400',
            },
        },
    };
};
