import { Box, Flex, useColorMode } from '@chakra-ui/react';
import { Zoom } from '@visx/zoom';
import type { Enable, ResizeCallback } from 're-resizable';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { visualTreeDimension } from '../../../state/ui/resizable-boards';
import { Tree } from './tree';
import { TreeCollapsed } from './tree-collapsed';
import { TreeZoomWrapper } from './tree-zoom-wrapper';

const VisualTreeCom: React.FC = () => {
    const { colorMode } = useColorMode();

    const [boardDimension, setBoardDimension] =
        useRecoilState(visualTreeDimension);

    const boxRef = React.useRef<HTMLDivElement>(null);
    const [width, setWidth] = React.useState<number>(0);

    // retrieve pixel width of the container
    useEffect(() => {
        const boxEl = boxRef.current;

        if (!boxEl) {
            return;
        }

        const syncWidth = () => setWidth(boxEl.getBoundingClientRect().width);

        // @ts-ignore This is well supported in modern browsers.
        const resizeObserver = new ResizeObserver(syncWidth);

        resizeObserver.observe(boxEl);

        syncWidth();

        return () => resizeObserver.unobserve(boxEl);
    }, [boardDimension.collapsed]);

    const currentHeight: number = +(boardDimension.height || '0');

    const resize: ResizeCallback = (_, __, ___, d) => {
        setBoardDimension({
            ...boardDimension,
            height: currentHeight + d.height,
        });
    };

    if (boardDimension.collapsed) {
        return <TreeCollapsed />;
    }

    const isDark = colorMode === 'dark';

    return (
        <Resizable
            onResizeStop={resize}
            size={{ width: '100%', height: currentHeight }}
            minHeight={200}
            enable={topOnly}
            handleComponent={{ top: handle }}
        >
            <Box
                ref={boxRef}
                width="100%"
                height="100%"
                position="relative"
                border="1px solid"
                borderColor={isDark ? 'gray.900' : 'gray.300'}
                bg={isDark ? 'gray.900' : 'gray.100'}
            >
                <Zoom width={width} height={currentHeight}>
                    {(zoom) => (
                        <TreeZoomWrapper
                            zoom={zoom}
                            width={width}
                            height={currentHeight}
                        >
                            <Tree />
                        </TreeZoomWrapper>
                    )}
                </Zoom>
            </Box>
        </Resizable>
    );
};

export const VisualTree = React.memo(VisualTreeCom);

const topOnly: Enable = {
    top: true,
    right: false,
    bottom: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
};

const handle = (
    <Flex
        h="100%"
        justifyContent="center"
        alignItems="center"
        opacity="0.8"
        _hover={{ opacity: '1' }}
    >
        <Box w="100px" h="4px" bg="gray.500" rounded="5px" />
    </Flex>
);
