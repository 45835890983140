import { FormControl, FormLabel, useColorMode } from '@chakra-ui/react';
import { TrimmedInput } from '@dmp/shared/components';
import type { FC } from 'react';
import { memo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    surveyNameState,
    surveyQQIDState,
} from '../../../state/survey/survey-atoms';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';

const SurveyNameCom: FC = () => {
    const surveyQQID = useRecoilValue(surveyQQIDState);
    const [surveyName, setSurveyName] = useRecoilState(surveyNameState);

    const { colorMode } = useColorMode();
    const { label, input } = trackingFieldStyles(colorMode);

    return (
        <>
            <FormControl id="survey-qqid" flex="0 0 100px">
                <FormLabel {...label}>qqid</FormLabel>
                <TrimmedInput
                    {...input}
                    value={surveyQQID}
                    isReadOnly
                    cursor="not-allowed"
                />
            </FormControl>

            <FormControl id="survey-name">
                <FormLabel {...label}>Survey Name</FormLabel>
                <TrimmedInput
                    {...input}
                    value={surveyName}
                    onChange={(e) => setSurveyName(e.target.value)}
                />
            </FormControl>
        </>
    );
};

export const SurveyName = memo(SurveyNameCom);
