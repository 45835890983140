import { HStack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { VscWarning as IconWarning } from 'react-icons/vsc';
import { useRecoilValue } from 'recoil';

import {
    coreQuestionShortIdBodySelector,
    coreQuestionShortIdIsActiveSelector,
} from '../../../../state/core-question/core-question-list-atoms';

interface CoreQuestionBodyProps {
    shortId: string;
}

const CoreQuestionBodyCom: React.FC<CoreQuestionBodyProps> = ({ shortId }) => {
    const text = useRecoilValue(coreQuestionShortIdBodySelector(shortId));
    const isActive = useRecoilValue(
        coreQuestionShortIdIsActiveSelector(shortId)
    );

    // NOTE: Do not use Tooltip here. Heavy rendering while dragging can cause error.
    return (
        <>
            <Text
                flex="1"
                fontWeight="bold"
                fontSize="sm"
                cursor="default"
                userSelect="none"
                opacity={isActive ? '1' : '0.5'}
                maxW="400px"
                noOfLines={1}
            >
                {text || shortId}
            </Text>

            {/* Note: Do not use Tooltip h */}
            {!isActive && (
                <HStack
                    fontSize="xs"
                    color="orange.500"
                    spacing="1"
                    title="Warning: This Question has been deprecated in Core Question Library"
                >
                    <IconWarning />
                    <i>deprecated</i>
                </HStack>
            )}
        </>
    );
};

export const CoreQuestionBody = React.memo(CoreQuestionBodyCom);
