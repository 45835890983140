import { HStack, Text, useColorMode } from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilState } from 'recoil';

import { isVisualTreeCollapsed } from '../../../state/ui/resizable-boards';
import { IconForkedQuestion } from '../../card-type-icons/icon-forked-question';

const TreeCollapsedCom: React.FC = () => {
    const { colorMode } = useColorMode();

    const [isCollapsed, setIsCollapsed] = useRecoilState(isVisualTreeCollapsed);

    if (!isCollapsed) {
        return null;
    }

    const isDark = colorMode === 'dark';

    return (
        <HStack
            px="2"
            cursor="pointer"
            borderTop="1px solid"
            borderColor={isDark ? 'gray.900' : 'gray.300'}
            bg={isDark ? 'gray.900' : 'white'}
            onClick={() => setIsCollapsed(false)}
            opacity="0.8"
            _hover={{ opacity: '1' }}
        >
            <IconForkedQuestion fontSize="xx-large" />
            <Text fontSize="sm">Connections Visualization</Text>
        </HStack>
    );
};

export const TreeCollapsed = React.memo(TreeCollapsedCom);
